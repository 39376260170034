<template lang="pug">
  table.table-layout(v-if='show' style='margin-left: auto; margin-right: 0;')
    tr
      td
        label(for='headerSearchRegionSelector') Searching
      td
        search-region-selector(
          app-code='TW'
          v-model.number='value.regionId'
          id='headerSearchRegionSelector'
        )
    template(v-if='$store.state.user.account.billingCodeEnabled')
      billing-code-input(v-model='value.billingCode')
</template>

<script>
import BillingCodeInput from '@/components/search/billing-code-input'
import SearchRegionSelector from '@/components/search/search-region-selector'

export default {
  name: 'tax-warrant-header-search-widget-top',
  components: {
    BillingCodeInput,
    SearchRegionSelector
  },
  props: {
    show: Boolean,
    value: Object
  }
}
</script>
