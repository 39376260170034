<template lang="pug">
  .v-input-wrapper(:data-help='!!$slots.info')
    label.v-input-wrapper__label(v-if='!hideLabel' :for='inputId')
      | {{ label }}
      template(v-if='required')
        | &nbsp;
        abbr(v-if='required' title='required field') *
      template(v-if='$slots.info')
        | &nbsp;
        help-popup.v-input-wrapper__info
          slot(name='info')
    .v-input-wrapper__input
      slot(v-bind='slotScope')
      .v-input-wrapper__errors(v-if='hasErrors' :id='errorId' aria-live='assertive' role='alert')
        p(v-for='message in errors.messages') {{message}}
</template>

<script>
import uid from '@/mixins/uid'
import { blankErrorsObject } from '@/assets/input-errors'
import HelpPopup from '@/components/help-popup'

export const WrapperProps = {
  label: {
    type: String,
    required: true,
  },
  hideLabel: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Object,
    default: () => {
      return blankErrorsObject()
    }
  },
}

export function collectProps (vm, props) {
  let scope = {}
  Object.keys(props).forEach(key => {
    scope[key] = vm[key]
  })
  return scope
}

export default {
  name: 'app-input-wrapper',
  mixins: [ uid ],
  components: {
    HelpPopup,
  },
  props: WrapperProps,
  computed: {
    slotScope () {
      return {
        inputId: this.inputId,
        errorId: this.errorId,
        hasErrors: this.hasErrors,
        ...collectProps(this, WrapperProps)
      }
    },
    inputId () {
      return 'v-input-wrapper__input-' + this.uid
    },
    errorId () {
      if (!this.hasErrors) return null
      return 'v-input-wrapper__errors-' + this.uid
    },
    hasErrors () {
      return this.errors && !!this.errors.messages.length
    },
  }
}
</script>

<style lang="scss">
@import 'src/assets/styles/colors';

.v-input-wrapper {

  &:not(:first-child) {
    margin-top: 1rem;
    &[data-help] {
      margin-top: 0.55rem;
    }
  }

  .v-modal__content > &:first-child {
    margin-top: 1rem;
  }

  &__label {
    display: block;
    font-weight: bold;

    .v-fieldset & {
      font-weight: normal;
      font-size: small;
    }
  }

  &__info {
    vertical-align: middle;
    font-weight: initial;
  }

  &__input {
    padding-top: 3px; // focus border
  }

  &__errors {
    color: map-deep-get($base-theme, fg, error, dark);
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
    > p {
      all: unset;
    }
  }

  &[data-type=checkbox] {
    > .v-input-wrapper__label {
      display: inline-block !important;
      // vertical-align: -moz-middle-with-baseline;
      margin-top: 0.35em;
      margin-left: 0.25rem;
    }
    > .v-input-wrapper__input {
      display: inline-block !important;
      float: left;
      clear: both;
    }
  }

}

@media screen and (min-width: 900px) {
  .table-layout {
    > .v-input-wrapper {
      display: table-row;

      > .v-input-wrapper__label {
        display: table-cell;
        padding-right: 0.25rem;
      }
      > .v-input-wrapper__input {
        all: unset;
        display: table-cell;
      }
    }
  }
}

</style>
