<template lang="pug">
  table.table-layout(v-if='show' style='margin-left: auto; margin-right: 0;')
    tr
      td._label
        label(for='headerSearchRegionSelector') Searching
      td
        search-region-selector(
          :app-code='l10n("app.code")'
          v-model.number='value.regionId'
          id='headerSearchRegionSelector'
        )
    template(v-if='$store.state.user.account.billingCodeEnabled')
      billing-code-input(v-model='value.billingCode')
</template>

<script>
import BillingCodeInput from '@/components/search/billing-code-input'
import SearchRegionSelector from '@/components/search/recorder-search-region-selector'
import l10n from '@/assets/recorder/l10n'

export default {
  name: 'recorder-header-search-widget-top',
  components: {
    BillingCodeInput,
    SearchRegionSelector
  },
  props: {
    show: Boolean,
    value: Object
  },
  methods: {
    l10n (key) {
      return l10n(this)(key)
    }
  }
}
</script>
<style lang="scss" module>
.label {
  vertical-align: top;
  padding-top: 7px;
}
</style>
